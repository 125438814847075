export function getMonthOptions() {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
}

export function getYearOptions() {
  const thisYear = new Date().getFullYear();
  return [thisYear, thisYear - 1, thisYear + 1];
}

type FileUploadResult = {
  signedUrl: string;
  fileKey: string;
};
export async function uploadFile(
  file: File,
  folderName: string,
  put: (url: string, data: any) => Promise<any>
): Promise<FileUploadResult | boolean> {
  const fileName = `${folderName}/${file.name}`;

  const { signedUrl, fileKey }: any = await put(
    `/api/uploads?fileName=${fileName}`,
    undefined
  );

  const response = await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });

  if (response.ok) return { signedUrl, fileKey };
  else return false;
}
