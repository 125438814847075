import { ArrowBack } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import { Link, useRouteError } from "react-router-dom";

export function ErrorPage() {
  const error: any = useRouteError();
  return (
    <Container component="main" sx={{ px: 12, mt: "15vh", paddingX: 6 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
          <p>
            <ArrowBack /> <Link to={"/"}>Return to loans page</Link>
          </p>
        </div>
      </Box>
    </Container>
  );
}
