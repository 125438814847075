import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/adminLayout";
import { LoadingIndicator } from "../components/loading";
import { useCallAPI } from "../hooks/useCallAPI";
import { PaymentReport } from "../types/models";

const ALL = "All";
const OverPaid = "Over";
const UnderPaid = "Under";
const ExactPayment = "Exact";
const MissingLoan = "Missing";
const NoPayment = "No Payment";

const FilterFunctions: any = {
  [ALL]: (rec: PaymentReport) => true,
  [OverPaid]: (rec: PaymentReport) => rec.amount > rec.monthlyPayment,
  [UnderPaid]: (rec: PaymentReport) => rec.amount < rec.monthlyPayment,
  [ExactPayment]: (rec: PaymentReport) => rec.amount >= rec.monthlyPayment,
  [MissingLoan]: (rec: PaymentReport) => !rec.loan,
  [NoPayment]: (rec: PaymentReport) => rec.amount === 0 && rec.loan,
};

export function PaySheetUploadReport() {
  const { period } = useParams();
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState(NoPayment);
  const [reportItems, setReportItems] = useState<PaymentReport[]>([]);
  const { user }: any = useAuthenticator((context) => [context.user]);
  const { get } = useCallAPI({
    apiEndpoint: window.awsConfig.apiEndpoint,
    accessToken: user.signInUserSession.accessToken.jwtToken,
  });

  useEffect(() => {
    get(`/api/paysheet/report?period=${period}`)
      .then((d) => {
        return d.map((x: any) => {
          return {
            ...x,
            monthlyPayment: parseFloat(x.monthlyPayment),
            amount: parseFloat(x.amount),
          };
        });
      })
      .then((data) => setReportItems(data))
      .finally(() => setLoading(false));
    // Should run once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFunction = FilterFunctions[filterValue];
  const filteredItems = reportItems.filter(filterFunction);

  function onFilterChanged(e: SelectChangeEvent<string>) {
    setFilterValue(e.target.value);
  }

  const pageTitle = `PaySheet Upload Report - ${period}`;

  return (
    <AdminLayout pageTitle={pageTitle}>
      <Stack width="100%">
        <Typography variant="h5" p={1}>
          {pageTitle}
        </Typography>
        {loading && <LoadingIndicator />}
        {!loading && (
          <TableContainer component={Paper}>
            <Box p={2}>
              <Select value={filterValue} onChange={onFilterChanged}>
                <MenuItem value={ALL}>All</MenuItem>
                <MenuItem value={NoPayment}>No Payment</MenuItem>
                <MenuItem value={MissingLoan}>Unknown Payment</MenuItem>
                <MenuItem value={UnderPaid}>Under Paid</MenuItem>
                <MenuItem value={OverPaid}>Over Paid</MenuItem>
                <MenuItem value={ExactPayment}>Paid Expected</MenuItem>
              </Select>
            </Box>
            <Table aria-label="loans table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Received(GHS)</TableCell>
                  <TableCell align="center">Expected(GHS)</TableCell>
                  <TableCell align="center">Difference(GHS)</TableCell>
                  <TableCell align="center">Emp. #</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.map((rec, idx) => (
                  <TableRow key={idx}>
                    <TableCell scope="row">{idx + 1}</TableCell>
                    <TableCell align="left">{rec.name}</TableCell>
                    <TableCell align="right">{rec.amount}</TableCell>
                    <TableCell align="right">
                      {rec.monthlyPayment || "None"}
                    </TableCell>
                    <TableCell align="right">
                      {Math.abs(rec.monthlyPayment - rec.amount).toFixed(2) ||
                        "None"}
                    </TableCell>
                    <TableCell align="left">{rec.employeeNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </AdminLayout>
  );
}
