import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "./login.css";

export function LoginScreen() {
  const { user, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  if (user && authStatus === "authenticated") {
    window.location.href = "/dashboard";
  }

  return (
    <Container component="main" sx={{ px: 12, mt: "15vh", paddingX: 6 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="div">
          <Grid container>
            <Grid md={3} item>
              <Authenticator hideSignUp></Authenticator>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
