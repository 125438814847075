import { Hub } from "aws-amplify";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import DashboardScreen from "../screens/dashboard";
import { ErrorPage } from "../screens/error-page";
import { PaySheetUploads } from "../screens/fileUpload";
import { PaySheetUploadReport } from "../screens/fileUploadReport";
import { LoginScreen } from "../screens/login/login";
import { ManageLoanScreen } from "../screens/manageLoan";
import { LoanStatement } from "../screens/loanStatement";

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route
        path="/"
        element={<LoginScreen />}
        errorElement={<ErrorPage />}
      ></Route>
      <Route
        path="dashboard"
        element={<DashboardScreen />}
        errorElement={<ErrorPage />}
      ></Route>
      <Route path="/loan/new" element={<ManageLoanScreen />} />
      <Route path="/loan/edit" element={<ManageLoanScreen />} />
      <Route path="/loan/statement" element={<LoanStatement />} />
      <Route path="/loan/sheet/uploads" element={<PaySheetUploads />} />
      <Route
        path="/loan/sheet/upload/:period/report"
        element={<PaySheetUploadReport />}
      />
    </React.Fragment>
  )
);

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signIn":
      window.location.href = "/dashboard";
      break;
    case "signOut":
      window.location.href = "/";
      console.log("Logging out");
      break;
  }
});
