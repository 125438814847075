import { useAuthenticator } from "@aws-amplify/ui-react";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./navigation";
import { LoginScreen } from "./screens/login/login";
import SetNewPassword from "./screens/login/setPassword";

function BecasaApp() {
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  if (!user) return <LoginScreen />;
  if (route === "forceNewPassword") {
    return <SetNewPassword user={user} />;
  }
  return <RouterProvider router={appRoutes} />;
}

export default BecasaApp;
