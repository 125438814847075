import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  Menu,
  MenuItem,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { useMemo } from "react";

type ProgressButtonProps = {
  progress?: CircularProgressProps;
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[] | string | undefined;
} & ButtonProps;

const progressPropsDefault = {
  size: 24,
  sx: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
};

export default function ProgressButton(props: ProgressButtonProps) {
  const { isLoading, progress, children, onClick, ...buttonProps } = props;

  const progressProps = { ...progressPropsDefault, ...(progress || {}) };
  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Button onClick={onClick} {...buttonProps}>
        {children}
      </Button>
      {isLoading && <CircularProgress {...progressProps} />}
    </Box>
  );
}

export function MenuButton({
  menu,
  children,
}: {
  menu: { title: string; onClick?: () => void }[];
  children?: JSX.Element | string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createMenuClickHandler = (onMenuClick?: () => void) => {
    return () => {
      onMenuClick && onMenuClick();
      setAnchorEl(null);
    };
  };

  const boundMenus = useMemo(() => {
    return menu.map(({ title, onClick }) => {
      return { title, onClick: createMenuClickHandler(onClick) };
    });
  }, [menu]);

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
      >
        {children || "Menu"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {boundMenus &&
          boundMenus.map((menu) => (
            <MenuItem key={menu.title} onClick={menu.onClick}>
              {menu.title}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
