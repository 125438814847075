import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#b71c1c",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        sx: { my: 2, minWidth: 120 },
      },
    },
  },
});
