import { useCallback } from "react";

type ApiConfig = {
  apiEndpoint: string;
  accessToken: string;
};
export function useCallAPI(options: ApiConfig) {
  const sendRequest = useCallback(
    async (url: string, data: any, method: string) => {
      console.log("Sending request");
      const response = await fetch(`${options.apiEndpoint}${url}`, {
        method,
        body: typeof data === "object" ? JSON.stringify(data) : undefined,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.accessToken}`,
        },
      });

      if (response.ok) {
        return (await response.json()) || true;
      }
    },
    [options.apiEndpoint, options.accessToken]
  );

  return {
    post: useCallback(
      async (url: string, data: any) => sendRequest(url, data, "POST"),
      [sendRequest]
    ),
    get: useCallback(
      async (url: string) => sendRequest(url, undefined, "GET"),
      [sendRequest]
    ),
    put: useCallback(
      async (url: string, data: any) => sendRequest(url, data, "PUT"),
      [sendRequest]
    ),
    httpDelete: useCallback(
      async (url: string, data: any) => sendRequest(url, data, "DELETE"),
      [sendRequest]
    ),
  };
}
