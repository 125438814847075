import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import FileUpload from "react-material-file-upload";
import AdminLayout from "../components/adminLayout";
import { LoadingIndicator } from "../components/loading";
import { PaySheetUpload } from "../types/models";
import * as yup from "yup";
import { getMonthOptions, getYearOptions, uploadFile } from "../lib/fileUpload";
import { useCallAPI } from "../hooks/useCallAPI";
import { Button, useAuthenticator } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";

const formSchema = yup.object({
  month: yup.string().required(),
  year: yup.string().required(),
});

const monthOptions = getMonthOptions();
const yearOptions = getYearOptions();

export function PaySheetUploads() {
  const { user }: any = useAuthenticator((context) => [context.user]);
  const { post, put, get, httpDelete } = useCallAPI({
    apiEndpoint: window.awsConfig.apiEndpoint,
    accessToken: user.signInUserSession.accessToken.jwtToken,
  });

  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState<PaySheetUpload[]>([]);

  const form = useFormik({
    initialValues: {
      month: "",
      year: "",
    },
    validationSchema: formSchema,
    onSubmit: () => {}, //Submission happens on file upload
  });

  useEffect(() => {
    get("/api/paysheets/")
      .then((sheets) => setUploads(sheets))
      .then(() => setLoading(false));
  }, [get]);

  const submitForm = async (values: any) => {
    console.log(values);
    const response = await post("/api/paysheets/", values);
    if (response) {
      // Ensure the same record is not shown twice
      const filteredUploads = uploads.filter((up) => up.sk !== response.sk);
      setUploads([response, ...filteredUploads]);
    }
  };

  async function handleFileUploaded(files: File[]) {
    const response = await uploadFile(files[0], "paysheets", put);
    if (!response) {
      alert("There was a problem uploading the file");
    } else {
      const { fileKey }: any = response;
      await submitForm({ ...form.values, file: fileKey });
      form.resetForm();
    }
  }

  async function handleDelete(month: string, year: number) {
    await httpDelete(`/api/paysheet/report/${month}-${year}/delete`, {
      status: "Deleted",
    });
    //reload
    window.location.reload();
  }

  const pageTitle = "Upload Sheet";

  return (
    <AdminLayout pageTitle={pageTitle}>
      <Stack width="100%">
        <Typography variant="h5">{pageTitle}</Typography>
        <Box>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item>
                <FormControl>
                  <InputLabel id="month">Month</InputLabel>
                  <Select
                    labelId="month"
                    name="month"
                    id="month"
                    onChange={form.handleChange}
                    value={form.values.month}
                  >
                    {monthOptions.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel id="year">Year</InputLabel>
                  <Select
                    labelId="year"
                    name="year"
                    id="year"
                    onChange={form.handleChange}
                    value={form.values.year}
                  >
                    {yearOptions.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FileUpload
                  sx={{ border: "0", height: 120 }}
                  value={[]}
                  maxFiles={1}
                  onChange={handleFileUploaded}
                  disabled={!form.dirty || !form.isValid}
                  accept={[".xlsx"]}
                />
              </Grid>
            </Grid>
          </Paper>

          {loading && <LoadingIndicator />}
          {!loading && (
            <TableContainer component={Paper}>
              <Table aria-label="loans table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Period</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploads.map(
                    ({ sk, data: { month, year, file }, jobStatus }) => (
                      <TableRow key={sk}>
                        <TableCell scope="row" align="center">
                          {month} {year}
                        </TableCell>
                        <TableCell align="center">
                          {jobStatus || "Processing"}
                        </TableCell>
                        {jobStatus === "Completed" && (
                          <>
                            <TableCell align="right">
                              <Link
                                to={`/loan/sheet/upload/${month}-${year}/report`}
                              >
                                See Report
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                onClick={() => handleDelete(month, year)}
                                size="small"
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Stack>
    </AdminLayout>
  );
}
