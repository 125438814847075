import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { CenteredBox } from "./centered";

enum SuccessFailure {
  Success = "success",
  Failure = "failure",
}

export function SuccessFailureBox({
  children,
  variant,
}: {
  children: JSX.Element | JSX.Element[] | string;
  variant?: SuccessFailure;
}) {
  return (
    <Paper elevation={1} sx={{ minWidth: 200 }}>
      <CenteredBox p={6}>
        {!variant || variant === SuccessFailure.Success ? (
          <CheckCircleOutlineOutlined color="success" sx={{ fontSize: 80 }} />
        ) : (
          <ErrorOutlineOutlined color="error" sx={{ fontSize: 40 }} />
        )}
        <Box p={3}>{children}</Box>
      </CenteredBox>
    </Paper>
  );
}
