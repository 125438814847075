import { Box, TextField, Container, Typography, Alert } from "@mui/material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { useState } from "react";
import ProgressButton from "../../components/buttons";

type SetNewPasswordFormProps = {
  newPassword: string;
  confirmNewPassword: string;
};

export default function SetNewPassword({ user }: { user: any }) {
  const [errorMessage, setErrorMessage] = useState("");
  const form = useFormik<SetNewPasswordFormProps>({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validate({ newPassword, confirmNewPassword }) {
      const errors: any = {};

      if (!newPassword) {
        errors.newPassword = "Required!";
      }

      if (newPassword && newPassword !== confirmNewPassword) {
        errors.confirmNewPassword = "Doesn't match your new password";
      }

      return errors;
    },
    onSubmit: async ({ newPassword }) => {
      console.log("Submit clicked");
      try {
        await Auth.completeNewPassword(user, newPassword);
        form.setSubmitting(false);
      } catch (ex) {
        setErrorMessage("We're sorry, something went wrong. Try again later");
        console.log(ex);
        form.setSubmitting(false);
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Change your password</Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Box component="div" sx={{ mt: 1, width: "80%" }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New password"
            type="password"
            id="newPassword"
            error={form.dirty && typeof form.errors.newPassword !== "undefined"}
            helperText={form.errors.newPassword || ""}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmNewPassword"
            label="Confirm new password"
            type="password"
            id="confirmNewPassword"
            error={
              form.dirty &&
              typeof form.errors.confirmNewPassword !== "undefined"
            }
            helperText={form.errors.confirmNewPassword || ""}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
          />
          <ProgressButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={form.submitForm}
            isLoading={form.isSubmitting}
          >
            Set New Password
          </ProgressButton>
        </Box>
      </Box>
    </Container>
  );
}
