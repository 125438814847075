import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import AdminLayout from "../components/adminLayout";
import { useLocation } from "react-router-dom";
import { Loan } from "../types/models";

const Datum = ({ label, value }: { label: string; value: string }) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <span style={{ fontWeight: 600 }}>{label}: </span>
      <span style={{ marginLeft: 8 }}>{value}</span>
    </div>
  );
};

export function LoanStatement() {
  const location = useLocation();
  const loanData: Loan = location.state ? location.state : undefined;
  const totalPaid =
    Object.keys(loanData.data.payments ?? {}).reduce((sum, key) => {
      return sum + Number(loanData.data.payments[key].amount);
    }, 0) ?? 0;

  return (
    <AdminLayout pageTitle="Statement">
      <Paper sx={{ p: 6, maxWidth: { sm: "100%", md: "50%" } }}>
        <Typography variant="h5">Loan Statement</Typography>
        <Divider style={{ marginBottom: 32 }} />
        <Stack>
          <Datum label="Employee #" value={loanData?.data?.employeeNumber} />
          <Datum
            label="Name"
            value={`${loanData?.data.firstName} ${loanData?.data.lastName}`}
          />
          <Datum label="Address" value={loanData?.data.address} />
          <Divider style={{ marginBottom: 8 }} />
          <Datum
            label="Loan Amount"
            value={`GHS${Number(loanData?.data?.saleAmount ?? 0).toFixed(2)}`}
          />
          <Datum
            label="Loan Term"
            value={`${loanData?.data?.termMonths} months`}
          />
          <Datum
            label="Interest Rate"
            value={`${(Number(loanData?.data?.interestRate) * 100).toFixed(
              2
            )}%`}
          />
          <Datum
            label="Monthly Payment"
            value={`GHS${Number(loanData?.data?.monthlyPayment ?? 0).toFixed(
              2
            )}`}
          />
          <Datum
            label="Total Paid"
            value={`GHS${Number(totalPaid).toFixed(2)}`}
          />
          <Datum
            label="Balance"
            value={`GHS${Number(loanData?.data?.saleAmount - totalPaid).toFixed(
              2
            )}`}
          />
          <Typography fontWeight={600} color={"grey"}>
            Payments
          </Typography>
          <Divider style={{ marginBottom: 8 }} />
          <TableContainer>
            <Table aria-label="payments table" size="small">
              <TableBody>
                {Object.keys(loanData?.data?.payments ?? {})
                  .sort()
                  .reverse()
                  .map((key, idx) => (
                    <TableRow
                      key={key}
                      sx={{ backgroundColor: idx % 2 === 1 ? "#fdfcfc" : null }}
                    >
                      {/* The payment key is in the format p_Jan-2023.LOAN#878978.89787 */}
                      <TableCell>{key.split(".")[0].split("_")[1]}</TableCell>
                      <TableCell>
                        {loanData?.data?.payments[key].amount}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
    </AdminLayout>
  );
}
