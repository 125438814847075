import { Box } from "@mui/material";

export function CenteredBox({
  children,
  p,
}: {
  children: JSX.Element | JSX.Element[] | string;
  p?: number;
}) {
  return (
    <Box
      sx={{
        p,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}
