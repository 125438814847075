import { useAuthenticator } from "@aws-amplify/ui-react";
import { Copyright, Menu } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MenuButton } from "./buttons";
import Logo from "./logo";
import { useMemo } from "react";

type AdminLayoutProps = {
  children?: JSX.Element | undefined;
  pageTitle?: string;
};

export default function AdminLayout({ children, pageTitle }: AdminLayoutProps) {
  const navigate = useNavigate();
  const { signOut, isPending } = useAuthenticator((context) => [context.user]);

  function handleLogout() {
    signOut();
  }

  function handleNewLoanClick() {
    navigate("/loan/new");
  }
  function handleToLoansClick() {
    navigate("/dashboard");
  }

  const breadcrumbs = useMemo(() => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <MuiLink
          color="inherit"
          underline="hover"
          href="/dashboard"
          onClick={(event) => {
            event.preventDefault();
            navigate("/dashboard");
          }}
        >
          Dashboard
        </MuiLink>
        <Typography color="text.primary">{pageTitle}</Typography>
      </Breadcrumbs>
    );
  }, [pageTitle, navigate]);

  if (isPending) {
    <Box>
      <CircularProgress />
    </Box>;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper
            sx={{ position: "absolute", top: 0, left: 0, width: "100%", p: 2 }}
            elevation={0}
          >
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Link to="/dashboard" style={{ textDecoration: "none" }}>
                  <Logo />
                </Link>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ mr: 3 }}
                  onClick={handleNewLoanClick}
                >
                  NEW LOAN
                </Button>
                <Button onClick={handleToLoansClick}>Loans</Button>
                <MenuButton
                  menu={[
                    {
                      title: "Imports",
                      onClick: () => navigate("/loan/sheet/uploads"),
                    },
                    { title: "Log out", onClick: handleLogout },
                  ]}
                >
                  <Box component={"span"} flex="row">
                    <Menu />
                  </Box>
                </MenuButton>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Container sx={{ padding: { sm: 0, md: 6 }, mt: 12 }}>
                {pageTitle && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {breadcrumbs}
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {children}
                </Box>
              </Container>
            </Grid>
          </Grid>
          <Copyright sx={{ pt: 4 }}>DeLorty 2022</Copyright>
        </Container>
      </Box>
    </Box>
  );
}
