import { CircularProgress } from "@mui/material";
import { CenteredBox } from "./centered";

export function LoadingIndicator() {
  return (
    <CenteredBox>
      <CircularProgress variant="indeterminate" />
    </CenteredBox>
  );
}
