import { Style } from "@mui/icons-material";
import { Typography } from "@mui/material";

export default function Logo() {
  return (
    <Typography variant="h4" color="#ff6f00" fontWeight={600}>
      <Style /> Becasa
    </Typography>
  );
}
