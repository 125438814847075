import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export function usePermissions() {
  const [permissions, setPermissions] = useState<any>({});
  useEffect(() => {
    Auth.currentSession().then((user: any) => {
      const userGroups = user?.accessToken.payload["cognito:groups"] || [];

      const isAdminUser = userGroups.indexOf("Administrator") >= 0;
      const isManager = userGroups.indexOf("LoanManager") >= 0;
      setPermissions({
        canManage: isAdminUser || isManager,
        canDelete: isAdminUser,
        canImport: isAdminUser || isManager,
      });
    });
    // Should be executed once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return permissions;
}
