import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import FileUpload from "react-material-file-upload";
import AdminLayout from "../components/adminLayout";
import ProgressButton from "../components/buttons";
import * as yup from "yup";
import { Button, useAuthenticator } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useState } from "react";
import { Loan, LoanEstimate, LoanTerm } from "../types/models";
import { useCallAPI } from "../hooks/useCallAPI";
import { SuccessFailureBox } from "../components/successBox";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadFile } from "../lib/fileUpload";
import { usePermissions } from "../hooks/userPermissions";

const formSchema = yup.object({
  firstName: yup.string().required().min(2).max(25),
  lastName: yup.string().required().min(2).max(25),
  address: yup.string().required().min(2).max(50),
  employeeNumber: yup.string().required().min(4).max(25),
  saleAmount: yup.number().min(100).default(100).max(999999999),
  interestRate: yup.number().required(),
  pfRate: yup.number().required(),
  pfAmount: yup.number(),
  monthlyPayment: yup.number(),
  termMonths: yup.number(),
  idCardFile: yup.string().optional(),
  photoFile: yup.string().optional(),
});

const FormState = {
  Success: "success",
  Failure: "Failure",
  Loading: "Loading",
  Loaded: "Loaded",
};

const initialValues = {
  firstName: "",
  lastName: "",
  address: "",
  employeeNumber: "",
  saleAmount: 100,
  termMonths: "",
  pfRate: "",
  pfAmount: "",
  interestRate: "",
  monthlyPayment: "",
  idCartFile: "",
  photoFile: "",
};

const IMAGES = [".png", ".jpg", ".jpeg"];

export function ManageLoanScreen(props?: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuthenticator((context) => [context.user]);
  const { get, post, put } = useCallAPI({
    apiEndpoint: window.awsConfig.apiEndpoint,
    accessToken: user.signInUserSession.accessToken.jwtToken,
  });
  const [formStatus, setFormStatus] = useState(FormState.Loaded);
  const [loanTerms, setLoanTerms] = useState<LoanTerm[]>([]);
  const [photoFile, setPhotoFile] = useState("");
  const [idCardFile, setIdCardFile] = useState("");
  const permissions = usePermissions();

  const loanData: Loan = location.state ? location.state : undefined;
  const form = useFormik({
    validationSchema: formSchema,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: loanData?.data || initialValues,
    onSubmit: async (values) => await submitForm({ ...values }),
  });

  const getLoanTerms = useCallback(() => get("/api/loanTerms/"), [get]);

  useEffect(() => {
    getLoanTerms().then((loanTerms) => setLoanTerms(loanTerms));
  }, [getLoanTerms, setLoanTerms]);

  useEffect(() => {
    if (!loanData || !loanData.pk) return;
    if (loanData.data.idCardFile) {
      get(`/api/uploads?fileName=${loanData.data.idCardFile}`).then((resp) =>
        setIdCardFile(resp.signedUrl)
      );
    }

    if (loanData.data.photoFile) {
      get(`/api/uploads?fileName=${loanData.data.photoFile}`).then((resp) =>
        setPhotoFile(resp.signedUrl)
      );
    }
  }, [loanData, loanData?.pk, get, setIdCardFile, setPhotoFile]);

  async function submitForm(values: any) {
    const response = await post("/api/loans/", {
      ...values,
      pk: loanData?.pk,
      sk: loanData?.sk,
      isOpen: loanData?.isOpen,
    });
    if (response) {
      setFormStatus(FormState.Success);
    } else {
      setFormStatus(FormState.Failure);
    }
  }

  async function recalculateLoanAmounts(months: number, saleAmount: number) {
    if (!months || !saleAmount) return;
    const estimate: LoanEstimate = await post("/api/loanTerms/", {
      saleAmount,
      months,
    });
    form.setFieldValue("interestRate", estimate.interestRate);
    form.setFieldValue("pfAmount", estimate.pfAmount);
    form.setFieldValue("pfRate", estimate.pfRate);
    form.setFieldValue("monthlyPayment", estimate.monthlyPayment);
  }

  async function handleTermMonthsChange(
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) {
    form.handleChange(event);
    const termMonths: any = event.target.value;
    await recalculateLoanAmounts(termMonths, form.values.saleAmount);
  }

  async function handleSaleAmountChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const termMonths: any = form.values.termMonths;
    const saleAmount: any = event.target.value;
    form.handleChange(event);
    form.setFieldValue("monthlyPayment", event.target.value);
    await recalculateLoanAmounts(termMonths, saleAmount);
  }

  async function handleFileUploaded(
    files: File[],
    folder: string,
    fieldName: string
  ) {
    const response = await uploadFile(files[0], folder, put);
    if (!response) {
      alert("There was a problem uploading the file");
    } else {
      const { fileKey }: any = response;
      await submitForm({
        ...form.values,
        photoFile: loanData.data?.photoFile,
        idCardFile: loanData.data?.idCardFile,
        [fieldName]: fileKey,
        isOpen: loanData?.isOpen,
      });
      await form.setFieldValue(fieldName, fileKey);
    }
  }
  async function uploadPhoto(files: File[]) {
    await handleFileUploaded(files, "photos", "photoFile");
  }

  async function uploadIdCard(files: File[]) {
    await handleFileUploaded(files, "idcards", "idCardFile");
  }

  function handleAddAnotherClick() {
    form.resetForm();
    setFormStatus(FormState.Loaded);
  }

  function handleSeeLoansClick() {
    navigate("/dashboard");
  }
  const pageTitle = location.state
    ? `${loanData?.data?.firstName} ${loanData?.data?.lastName}`
    : "New Loan";

  if (formStatus === FormState.Success) {
    return (
      <AdminLayout pageTitle={pageTitle}>
        <Box mt={12}>
          <SuccessFailureBox>
            <Typography>Loan has been saved successfully!</Typography>
            <Box mt={6}>
              <Button variation="link" onClick={handleAddAnotherClick}>
                Add another
              </Button>
              <Button variation="link" onClick={handleSeeLoansClick}>
                See Loans
              </Button>
            </Box>
          </SuccessFailureBox>
        </Box>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout pageTitle={pageTitle}>
      <Paper sx={{ p: 6, maxWidth: { sm: "100%", md: "50%" } }}>
        {!location.state && <Typography variant="h5">New Loan</Typography>}
        {location.state && (
          <Typography variant="h5">
            {loanData.data.firstName} {loanData.data.lastName}
          </Typography>
        )}
        <Stack direction="row">
          <TextField
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="First name"
            id="firstName"
            error={form.dirty && typeof form.errors.firstName !== "undefined"}
            helperText={form.errors.firstName || ""}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
            value={form.values.firstName}
          />

          <TextField
            sx={{ ml: 2 }}
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Last name"
            id="lastName"
            error={form.dirty && typeof form.errors.lastName !== "undefined"}
            helperText={form.errors.lastName || ""}
            onChange={form.handleChange}
            disabled={form.isSubmitting}
            value={form.values.lastName}
          />
        </Stack>
        <TextField
          margin="normal"
          required
          fullWidth
          name="address"
          label="Address"
          type="text"
          multiline={true}
          rows={2}
          id="address"
          error={form.dirty && typeof form.errors.address !== "undefined"}
          helperText={form.errors.address || ""}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          value={form.values.address}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="employeeNumber"
          label="Employee #"
          id="employeeNumber"
          error={
            form.dirty && typeof form.errors.employeeNumber !== "undefined"
          }
          helperText={form.errors.employeeNumber || ""}
          onChange={form.handleChange}
          disabled={form.isSubmitting}
          value={form.values.employeeNumber}
        />

        <Stack direction="row">
          <TextField
            margin="normal"
            required
            fullWidth
            sx={{ mr: 2 }}
            name="saleAmount"
            label="Sale"
            id="saleAmount"
            type="number"
            error={form.dirty && typeof form.errors.saleAmount !== "undefined"}
            helperText={form.errors.saleAmount || ""}
            onChange={handleSaleAmountChange}
            disabled={form.isSubmitting}
            value={form.values.saleAmount}
          />

          <Select
            required
            fullWidth
            name="termMonths"
            label="Term (Months)"
            id="termMonths"
            error={form.dirty && typeof form.errors.termMonths !== "undefined"}
            onChange={handleTermMonthsChange}
            disabled={form.isSubmitting}
            value={form.values.termMonths.toString()}
          >
            {loanTerms &&
              loanTerms.map((term) => (
                <MenuItem key={term.months} value={term.months}>
                  {term.months} Months
                </MenuItem>
              ))}
          </Select>
        </Stack>
        <Stack direction="row">
          <TextField
            margin="normal"
            required
            fullWidth
            name="interestRate"
            label="Interest (%)"
            id="interestRate"
            error={
              form.dirty && typeof form.errors.interestRate !== "undefined"
            }
            helperText={form.errors.interestRate || ""}
            onChange={form.handleChange}
            value={form.values.interestRate}
            disabled={true}
          />

          <TextField
            sx={{ ml: 2 }}
            margin="normal"
            required
            fullWidth
            name="pfAmount"
            label="PF"
            id="pfAmount"
            error={form.dirty && typeof form.errors.pfAmount !== "undefined"}
            helperText={form.values.pfRate && `PF Rate: ${form.values.pfRate}`}
            onChange={form.handleChange}
            value={form.values.pfAmount}
            disabled={true}
          />
          <TextField
            sx={{ ml: 2 }}
            margin="normal"
            required
            fullWidth
            name="monthlyPayment"
            label="Monthly"
            id="monthlyPayment"
            error={
              form.dirty && typeof form.errors.monthlyPayment !== "undefined"
            }
            helperText={form.errors.monthlyPayment || ""}
            onChange={form.handleChange}
            disabled={true}
            value={form.values.monthlyPayment}
          />
        </Stack>
        {loanData && loanData.sk && (
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Attachments (Photo)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography>Photo</Typography>
                {!photoFile && (
                  <FileUpload
                    value={[]}
                    onChange={uploadPhoto}
                    maxFiles={1}
                    accept={IMAGES}
                  />
                )}
                {photoFile && <ImageBox src={photoFile} />}
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography>ID Card</Typography>
                {!idCardFile && (
                  <FileUpload
                    value={[]}
                    onChange={uploadIdCard}
                    maxFiles={1}
                    accept={IMAGES}
                  />
                )}
                {idCardFile && <ImageBox src={idCardFile} />}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {permissions.canManage && (
          <ProgressButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={form.submitForm}
            isLoading={form.isSubmitting}
            disabled={!form.isValid || !form.dirty}
          >
            Save
          </ProgressButton>
        )}
      </Paper>
    </AdminLayout>
  );
}

const imageStyles = {
  maxWidth: "60%",
  maxHeight: "120px",
};
function ImageBox({ src }: { src: string }) {
  return (
    <Box>
      <img src={src} style={imageStyles} alt="Customer pic" />
    </Box>
  );
}
