import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TableContainer,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../components/adminLayout";
import { LoadingIndicator } from "../components/loading";
import { useCallAPI } from "../hooks/useCallAPI";
import { usePermissions } from "../hooks/userPermissions";
import { Entity, Loan } from "../types/models";

export default function DashboardScreen() {
  const navigate = useNavigate();
  const { user }: any = useAuthenticator((context) => [context.user]);
  const { get, httpDelete } = useCallAPI({
    apiEndpoint: window.awsConfig.apiEndpoint,
    accessToken: user.signInUserSession.accessToken.jwtToken,
  });

  const [loading, setLoading] = useState(true);
  const [loans, setLoans] = useState<Loan[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const permissions = usePermissions();

  const fetchLoans = useCallback(
    (searchTerm: string) => get(`/api/loans/?search=${searchTerm || ""}`),
    [get]
  );

  useEffect(() => {
    fetchLoans(searchTerm).then((loans) => {
      setLoans(loans);
      setLoading(false);
    });
  }, [searchTerm, fetchLoans, setLoans, setLoading]);

  // Apply search term after 250ms of inactivity
  useEffect(() => {
    if (!searchFieldValue) {
      setTimeout(() => {
        !searchFieldValue && setSearchTerm(searchFieldValue);
      }, 250);
    }
  }, [searchFieldValue, setSearchTerm]);

  const handleSearch = useCallback(
    function () {
      setSearchTerm(searchFieldValue);
    },
    [searchFieldValue, setSearchTerm]
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSearch();
      }
    },
    [handleSearch]
  );

  const deleteHandler = useCallback(
    function (loan: Entity) {
      if (window.confirm("Are you sure you want to delete?")) {
        httpDelete("/api/loans/", loan).then(() => {
          const newList = loans.filter(
            ({ pk, sk }) => loan.pk !== pk && loan.sk !== sk
          );
          setLoans(newList);
        });
      }
    },
    [loans, setLoans, httpDelete]
  );

  return (
    <AdminLayout>
      <Stack width="100%">
        <Box>
          <Paper
            component="form"
            sx={{
              p: "4px 8px",
              display: "flex",
              alignItems: "center",
              width: 400,
              borderRadius: 12,
              height: 40,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              size="small"
              placeholder="Search by name or employee number"
              inputProps={{ "aria-label": "search loans" }}
              onChange={(e) => setSearchFieldValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <Search />
            </IconButton>
            <Divider orientation="vertical" />
          </Paper>
        </Box>
        <Box>
          <Paper sx={{ mt: 2 }}>
            {loading && <LoadingIndicator />}
            {!loading && (
              <TableContainer component={Paper}>
                <Table aria-label="loans table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Employee #</TableCell>
                      <TableCell align="right">Full name</TableCell>
                      <TableCell align="right">Sale(GHS)</TableCell>
                      <TableCell align="right">PF(GHS)</TableCell>
                      <TableCell align="right">Detail</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loans.map(({ data, sk, pk }) => (
                      <TableRow key={sk}>
                        <TableCell scope="row">{data.employeeNumber}</TableCell>
                        <TableCell align="right">
                          {data.firstName} {data.lastName}
                        </TableCell>
                        <TableCell align="right">{data.saleAmount}</TableCell>
                        <TableCell align="right">{data.pfAmount}</TableCell>
                        <TableCell align="right">
                          {data.monthlyPayment} monthly
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            variation="link"
                            onClick={() =>
                              navigate("/loan/statement", {
                                state: { data, sk, pk },
                              })
                            }
                          >
                            View
                          </Button>
                          {permissions.canManage && (
                            <Button
                              size="small"
                              variation="link"
                              onClick={() =>
                                navigate("/loan/edit", {
                                  state: { data, sk, pk },
                                })
                              }
                            >
                              Edit
                            </Button>
                          )}
                          {permissions.canDelete && (
                            <Button
                              size="small"
                              color="red"
                              variation="link"
                              onClick={() => deleteHandler({ pk, sk })}
                            >
                              Delete
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Box>
      </Stack>
    </AdminLayout>
  );
}
